html,
body,
#root {
    display: block;
    position: relative;
    width: 100%;
    min-height: calc(100vh - 125px);
}

body {
    font: 1.2em/1.62 'Poppins', sans-serif;
    color: white;
    background: #272a4d;
    letter-spacing: 1px;
}

h1,
h2,
h3 {
    line-height: 1.2;
}

@media print {
    body {
        max-width: none
    }
}

a.link {
    color: #a8cf44;
    text-decoration: none;
    display: inline-block;
    position: relative;

    &:hover::after {
        content: "";
        border-bottom: 1px solid #a8cf44;
        left: 0;
        right: 0;
        position: absolute;
        bottom: 2px;
    }
}

ul.colored-bullets,
ul.colored-arrows {
    list-style-type: none;

    li {

        &::before {
            content: "-";
            // content: "•";
            color: #a8cf44;
            left: -9px;
            position: relative;
            font-size: 1.5em;
            line-height: 0em;
            top: 0.1em;
        }

        a {
            color: white;
            text-decoration: none;

            &:hover {
                color: #a8cf44;
            }
        }
    }

    @media (max-width: 991px) {
        padding-left: 15px;
    }
}

ul.colored-arrows li::before {
    content: ">";
}


#root {
    padding-bottom: 125px;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    min-height: 30vh;
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #98d400;
    animation: spinner .6s linear infinite;
}

.upperLayer {
    z-index: 2;
    position: relative;
}

@media (max-width: 991px) {
    body {
        font-size: 1em;
    }

}

@-webkit-keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

@-moz-keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

@keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

.scroll-btn {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    opacity: 0.6;
    transition: opacity 100ms;

    &:hover {
        opacity: 1;
    }
}

.scroll-btn>* {
    line-height: 18px;
    font-size: 12px;
    font-weight: normal;
    color: #7f8c8d;
    color: #ffffff;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
}

.scroll-btn>*:hover,
.scroll-btn>*:focus,
.scroll-btn>*.active {
    color: #ffffff;
}

.scroll-btn>*:hover,
.scroll-btn>*:focus,
.scroll-btn>*:active,
.scroll-btn>*.active {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.scroll-btn .mouse {
    position: relative;
    display: block;
    width: 26px;
    height: 42px;
    margin: 0 auto 10px;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 23px;
}

.scroll-btn .mouse>* {
    position: absolute;
    display: block;
    top: 29%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
    background: white;
    border-radius: 50%;
    -webkit-animation: ani-mouse 2.5s linear infinite;
    -moz-animation: ani-mouse 2.5s linear infinite;
    animation: ani-mouse 2.5s linear infinite;
}

.team {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: calc(100% + 20px);
    margin: -10px;
}

.member {
    display: flex;
    flex-flow: column;
    align-items: baseline;
    margin: 30px 11px;
    flex-basis: auto;
    justify-content: start;

    .image {
        background-color: rgba(0, 0, 0, 0.16);
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0;
        border-radius: 2px;
        width: 200px;
        height: 300px;
    }

    .details {
        font-size: 0.7em;
        flex-flow: column;
        justify-content: space-between;
        font-weight: 200;
        max-width: 200px;
        display: block;
        word-break: break-word;
        padding: 10px 0;

        .name {
            font-weight: 400;
            font-size: 1.4em;
            line-height: 1;
            margin-bottom: 0px;
            min-height: 36px;
        }

        .position {
            top: 7px;
            position: relative;
            color: #95aac5;
        }

        .phonenumber {
            top: 8px;
            position: relative;
            margin-bottom: 4px;
        }

        a {
            color: white;
            text-decoration: none;
            margin-right: .5em;

            &:hover {
                color: #a8cf44;
            }
        }
    }
}